import styled from 'styled-components';

const Page1 = () => {

	return (
		<Container>
			Hi
		</Container>

	);
};

// styled components

const Container = styled.div`

`;

export default Page1;
