import styled from 'styled-components';
import Plausible from 'plausible-tracker'


const Home = () => {

	const { trackEvent } = Plausible()
	
	const test = () => {
		trackEvent('Test')	
	}

	return (
		<Container>
			<button onClick={test}>Score1</button>
			Home page
		</Container>);
};

// styled component

const Container = styled.div`
	position: relative;
`;

export default Home;