import { useRoutes } from 'react-router-dom';
import Home from './routes/Home';
import Page1 from './routes/Page1';
import Page2 from './routes/Page2';

const Routing = () => {

	const Route = () => useRoutes([
		{ path: '/', element: <Home/>},
		{ path: '/home', element: <Home/>},
		{ path: '/page1', element: <Page1/>},
		{ path: '/page2', element: <Page2/>},
	]);

	return (	
		<>
			<Route/>
		</>
	);
};

export default Routing;