import styled from 'styled-components';

const Page2 = () => {

	return (
		<Container>
			Hi page 2
		</Container>

	);
};

// styled components

const Container = styled.div`

`;

export default Page2;
